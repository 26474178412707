import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function OnPageSeoPage(props) {
    return (
        <div className="container draft-view">
           <h1>On-page SEO</h1>
           <p className="text-muted">For your business, the on-page SEO is the most effective way to drive valuable traffic and gain qualified leads for your site which could potentially be converted into customers.</p>
           <p className="text-muted">You can expect substantial business growth from our on-page optimization services. Wescale web agency provides on-page SEO services according to your needs, purposes, and budget in order to be your partner in growth.</p>
           <p className="text-muted"><br /></p>
           <h2>How on-page SEO can grow your business</h2>
               <p> Every business, big or small, needs some kind of Internet presence today. So by investing in your website, you are investing in the success of your business. However, without organic traffic, all of your efforts will be rendered futile. On-page optimization is a range of methods that optimize the most important web pages in order to rank higher and earn more relevant traffic from search engines. The most valuable ranking factors of search engines are page-level factors. That is why the effect of content marketing and off-page SEO needs quality on-page SEO to improve the search rank of your website. </p>
               <p className="text-muted"></p>
           <p> On-page SEO is very effective for eCommerce, corporate websites, and blogs. For small businesses with limited budgets, on-page optimization can be the only way to be successful in the market. Also, digital advertising platforms assess the quality of the pages. For example, Google Ads assesses the page’s user experience, keyword relevance to expect clickthrough rate. So, you need the on-page optimization even if you only drive paid traffic. If you want to grow your business through your website, you need a quality on-page SEO. </p>
           <p className="text-muted"><br /></p>
           <h2 >On-page SEO factors</h2><p  className="text-muted">Only some on-page SEO factors affect website rank directly, but all factors are very important for website success.</p>
           <p className="text-muted"></p>
           <h5>1. URL structure</h5>
           <p className="text-muted">URL structure should be search engine friendly to clearly show the hierarchy of the page on a website. Why is it important? In 2018 an e-commerce site had over 50,000 pages removed from the Google index. This happened because a hard-to-understand URL structure made search engines assume that these pages are irrelevant.</p>
           <p className="text-muted"></p>
           <h5>2. Meta title and meta descriptions</h5><p  className="text-muted">Each page must have a unique and descriptive title that will help both search engines and users understand what the page is about. It is reasonable to use keywords in the title tag. The description is what people will see on the search engine results page. Meta tag descriptions need to be specific and attractive for each page.</p>
           <p className="text-muted"></p>
           <h5>3. Headings</h5>
            <p className="text-muted">Using headings (h1, h2, h3 tags) helps search engines to better understand the content. Rather than reading the whole page, most users just scan any new site page. They will usually pick out individual words and sentences. Crawlers have the same behavior. Our specialists can create interesting and meaningful headings that naturally include keywords.</p>
            <p className="text-muted"></p>
            <h5>4. Page content</h5>
            <p  className="text-muted">Search engines index main and supplementary content on pages. The key factors of content are topic relevance, diversity of the topical content around the main theme, and natural language. It is important to use formatting (numbered and bulleted lists, bold, strong, underline, or italics to highlight the important parts). Content management is our strength.</p>
            <p className="text-muted"></p>
            <h5>5. Images and media SEO</h5>
            <p className="text-muted">Images make a page more interesting and easier to understand for people. Size optimization helps to increase the loading speed of a page. Using the ALT tag is extremely helpful for search engines. Images are particularly important for product pages. Wescale SEO specialists and graphic designers help you to optimize images and media on a page in the right way.</p>
            <p className="text-muted"></p>
            <h5>6. Internal and external links</h5>
            <p  className="text-muted">Any page on your website includes internal and external links. Internal links allow search engine spiders to discover new pages and follow them to other pages. It helps search engines to understand the content of pages better. External links play the same role. Also, it’s a good sign for search engines if links provide a useful user experience. We’ll help you learn how to properly use links.</p>
            <p className="text-muted"></p>
            <p  className="text-muted">While we all want to be on page one of Google search results, with Wescale specialists your website will attract more clients. Contact us for on-page SEO services.<br /></p>

            </div>
    );
}
